<template>
	<v-card class="status " width="100%" flat max-height="400">
		<v-card-text
			class=" status__text d-flex align-center justify-space-between pa-0"
		>
			<v-card-title class="status__text__title" color="black">
				Oportunidades
			</v-card-title>
			<v-btn
				color="rgba(30, 144, 255, 0.2)"
				class="status__text__button mr-4"
				elevation="0"
				type="submit"
				:to="{ name: 'job-vacancies' }"
				>Adicionar Vagas</v-btn
			>
		</v-card-text>
		<v-list class="status__list">
			<v-list-item
				link
				v-for="(item, index) in opportunities"
				:key="index"
				class=" status__list__item "
			>
				<div class="status__list__item__content">
					<div class="status__list__item__content__text">
						<v-avatar color="grey mr-5" size="56">{{ item.avatar }}</v-avatar>
						<span>{{ item.vaga }}</span>
					</div>
					<v-btn
						color="rgba(76, 175, 80, 0.2)"
						class="status__list__item__content__button "
						elevation="0"
						type="submit"
						>15 candidatos</v-btn
					>
				</div>
				<v-divider inset />
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		opportunities: [
			{
				vaga: 'Desenvolvedor Front-End',
				avatar: '',
			},
			{
				vaga: 'Desenvolvedor Back-End',
				avatar: '',
			},
			{
				vaga: 'UI Designer',
				avatar: '',
			},
			{
				vaga: 'Análista de QA',
				avatar: '',
			},
			{
				vaga: 'Desenvolvedor Mobile',
				avatar: '',
			},
		],
	}),
}
</script>

<style lang="scss" scoped>
.status {
	overflow-y: auto;
	&__text {
		&__title {
			color: rgba(0, 0, 0, 0.87);
			font-weight: 500;
			font-size: 20px;
			line-height: 32px;
		}
		&__button {
			width: 193px;
			height: 36px !important;
			font-size: 14px !important;
			line-height: 36px !important;
			font-weight: 'Roboto' !important;
			color: #1e90ff !important;
		}
	}
	&__list {
		&__item {
			flex-wrap: wrap;
			padding-top: 8px;
			&__content {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 8px;
				span {
					font-size: 16px;
					line-height: 26px;
					color: rgba(0, 0, 0, 0.87);
				}
				&__text {
				}
				&__button {
					width: 130px;
					height: 27px !important;
					font-size: 12px !important;
					line-height: 18px !important;
					font-weight: 600 !important;
					color: #4caf50 !important;
					text-transform: capitalize;
				}
			}
		}
		.v-list-item {
			min-height: 0;
		}
	}
}
</style>
