<template>
	<div class="teams">
		<Header />
		<Groups />
		<div class="teams__cards">
			<Management />
			<Projects />
		</div>
	</div>
</template>

<script>
import Header from '@/components/unique/Teams/Header'
import Groups from '@/components/unique/Teams/Groups'
import Management from '@/components/unique/Teams/Management'
import Projects from '@/components/unique/Teams/Projects'

export default {
	data: () => ({}),
	components: {
		Header,
		Groups,
		Management,
		Projects,
	},
}
</script>

<style lang="scss" scoped>
.teams {
	&__cards {
		display: flex;
		justify-content: space-between;
	}
}
</style>
