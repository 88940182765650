<template>
	<v-card class="card-resources" flat max-height="400">
		<v-card-text class="card-resources__action">
			<v-card-title class="card-resources__action__title"
				>Meus recursos</v-card-title
			>
			<v-btn
				depressed
				color="rgba(30, 144, 255, 0.2)"
				class="card-resources__action__button"
				:to="{ name: 'new-resource' }"
				>Adicionar recurso</v-btn
			>
		</v-card-text>

		<v-container fluid>
			<v-list class="card-resources__list" max-height="400">
				<v-row>
					<v-col
						md="6"
						v-for="(item, index) in resources"
						:key="index"
						class="column"
					>
						<v-list-item link class="card-resources__list__item">
							<div class="card-resources__list__item__person">
								<v-list-item-avatar size="56">
									<v-img :src="item.avatar" />
								</v-list-item-avatar>
								<v-list-item-title>{{ item.name }}</v-list-item-title>
							</div>
							<v-divider inset />
						</v-list-item>
					</v-col>
				</v-row>
			</v-list>
		</v-container>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		resources: [
			{
				name: 'Amanda Silva',
				avatar:
					'https://avatars2.githubusercontent.com/u/59808416?s=460&u=58981469d609584af2a665aa29ed021677e63387&v=4',
			},
			{
				name: 'Diego Silva',
				avatar:
					'https://igd-wp-uploads-pluginaws.s3.amazonaws.com/wp-content/uploads/2016/05/30105213/Qual-e%CC%81-o-Perfil-do-Empreendedor.jpg',
			},
			{
				name: 'Alex Moreira',
				avatar:
					'https://images.endeavor.org.br/uploads/2016/09/deli-matsuo-2.jpg',
			},
			{
				name: 'Vanderson Mota',
				avatar:
					'https://geracaocooperacao.com.br/wp-content/uploads/2016/07/jackson-uniodonto.jpg',
			},
			{
				name: 'Felipe Luciano',
				avatar:
					'https://avatars2.githubusercontent.com/u/59808416?s=460&u=58981469d609584af2a665aa29ed021677e63387&v=4',
			},
			{
				name: 'Gabriel Caiana',
				avatar:
					'https://avatars2.githubusercontent.com/u/59808416?s=460&u=58981469d609584af2a665aa29ed021677e63387&v=4',
			},
			{
				name: 'Rodolfo',
				avatar:
					'https://avatars2.githubusercontent.com/u/59808416?s=460&u=58981469d609584af2a665aa29ed021677e63387&v=4',
			},
			{
				name: 'Matheus Santos',
				avatar:
					'https://avatars2.githubusercontent.com/u/59808416?s=460&u=58981469d609584af2a665aa29ed021677e63387&v=4',
			},
			{
				name: 'Allan Santos',
				avatar:
					'https://avatars2.githubusercontent.com/u/59808416?s=460&u=58981469d609584af2a665aa29ed021677e63387&v=4',
			},
		],
	}),
}
</script>

<style lang="scss" scoped>
.card-resources {
	width: 100%;
	overflow-y: auto;
	&__action {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__title {
			color: rgba(0, 0, 0, 0.87);
			font-weight: 500;
			font-size: 20px;
			line-height: 32px;
			padding: 0;
		}
		&__button {
			color: #1e90ff;
		}
	}
	&__list {
		.column {
			padding: 0;
		}
		&__item {
			flex-wrap: wrap;
			width: 100%;
			&__person {
				display: flex;
				width: 100%;
			}
		}
		.v-list-item {
			min-height: 0;
		}
	}
}
</style>
