<template>
	<v-card flat class="card-projects" max-height="500">
		<v-card-text class="card-projects__action">
			<v-card-title class="card-projects__action__title"
				>Projetos das minhas equipes</v-card-title
			>
			<v-menu
				offset-y
				left
				origin="top left"
				transition="scale-transition"
				:close-on-content-click="false"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-icon>keyboard_arrow_down</v-icon>
					</v-btn>
				</template>

				<v-list class="card-projects__list-menu">
					<v-list-item
						v-for="(item, index) in items"
						:key="index"
						class="card-projects__list-menu__item"
						link
					>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-text>

		<v-divider />

		<v-list class="card-projects__list">
			<v-list-item
				link
				v-for="(item, index) in list"
				:key="index"
				class="card-projects__list__item"
			>
				<v-avatar color="grey" class="card-projects__list__item__avatar">
					{{ item.initials }}
				</v-avatar>
				<v-list-item-title class="card-projects__list__item__title">
					{{ item.name }}
				</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		items: [{ title: 'Ativos' }, { title: 'Finalizados' }],
		list: [
			{
				initials: 'AN',
				name: 'Angis',
			},
			{
				initials: 'EM',
				name: 'E-cargo Mobile',
			},
			{
				initials: 'EC',
				name: 'E-cargo',
			},
			{
				initials: 'SS',
				name: 'Sistema Signa',
			},
			{
				initials: 'ST',
				name: 'Site signa',
			},
			{
				initials: 'SM',
				name: 'Smart Tag',
			},
		],
	}),
}
</script>

<style lang="scss" scoped>
.card-projects {
	overflow-y: auto;
	width: 30%;
	margin-bottom: 50px;
	border-radius: 10px;
	&__action {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__title {
			color: rgba(0, 0, 0, 0.87);
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			padding: 0;
		}
	}
	&__list {
		&__item {
			padding: 10px 15px;
			&__avatar {
				color: #717786;
			}
			&__title {
				padding-left: 15px;
				color: #717786;
			}
		}
	}
}
</style>
