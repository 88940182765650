<template>
	<v-card class="card" flat>
		<v-tabs
			fixed-tabs
			flat
			v-model="tab"
			background-color="#fff"
			class="card__tabs"
		>
			<v-tab>
				Equipes
			</v-tab>
			<v-tab>
				Recursos
			</v-tab>
			<v-tab>
				Oportunidades
			</v-tab>
		</v-tabs>
		<v-divider></v-divider>

		<v-tabs-items v-model="tab">
			<v-tab-item>
				<Teams />
			</v-tab-item>
			<v-tab-item>
				<Resources />
			</v-tab-item>
			<v-tab-item>
				<Opportunities />
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
import Teams from '@/components/unique/Teams/Teams'
import Resources from '@/components/unique/Teams/Resources'
import Opportunities from '@/components/unique/Teams/Opportunities'
export default {
	data: () => ({
		tab: null,
	}),
	components: {
		Teams,
		Resources,
		Opportunities,
	},
}
</script>

<style lang="scss" scoped>
.card {
	width: 65%;
	margin-bottom: 50px;
	padding-bottom: 50px;
	border-radius: 10px;
}
</style>
